import debounce from '../debounce';

// Used to add fixing of "next" button behavior on redeem step2
module.exports = function drupalBehaviors_fixButtonOnRedeem() {
    Drupal.behaviors.fixButtonOnRedeem = {
      attach: function(context, settings) {
        (function($) {
          // Check if this is the right page
          if (!$('body').hasClass('page-keuze-cadeaubon-step-one')) return;
  
          // Add wrapper around button for styling
          $('.fixed-bottom-button').once().wrap('<div class="fixed-bottom-navigation"></div>');
          function fixFooterButton() {
            var contentBlock = $('.page-keuze-cadeaubon-step-one #main-content .view-content');
            if (contentBlock.length){
              var contentBlockStartPosition = contentBlock.offset().top;
              var contentBlockEndPosition = contentBlockStartPosition + contentBlock.height();
              var startingZone = contentBlockStartPosition < $(window).scrollTop();
              var endingZone = contentBlockEndPosition > $(window).scrollTop() + $(window).height();
              var fixedPosition = startingZone && endingZone;
  
              if (fixedPosition) {
                $('body').addClass('fixedFooter');
              } else {
                $('body').removeClass('fixedFooter');
              }
            } 
            else {
              return;
            }
            
          }
  
          // Add debounce of scrolling function
          var handleFixFooter = debounce(function() {
            fixFooterButton();
          }, 50)
  
          $(window).scroll(handleFixFooter);
  
        })(jQuery);
      }
    };
  }