
// Fix #navbar on top of the page on scroll event

module.exports = function fixNavBarOnScroll() {
  (function($) {

    function handleScroll() {
        if ($(window).scrollTop() > 178) {
            $('body').addClass('fixedHeader');
        } else {
            $('body').removeClass('fixedHeader');
        }
    }

    $(window).on('scroll', handleScroll);

    // using passive:true flag for better performance
    // (means that event will not be prevented)
    window.addEventListener('scroll', handleScroll, {passive: true});

  })(jQuery);
}
