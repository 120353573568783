
// Adds a class of last child to last child element like p or blockquote

module.exports = function drupalBehaviors_lastChilds() {
  Drupal.behaviors.nxte_last_childs = {
    attach: function(context, settings) {
      (function($) {
        $('p:last-child, blockquote:last-child').each(function() {
                if (!$(this).hasClass('last-child')) {
                    $(this).addClass('last-child');
                }
            });
      })(jQuery);
    }
  };
}
