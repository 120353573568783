// Show tooltip in bottom navigation of redeem step 2 and 3
module.exports = function drupalBehaviors_showCartTooltipRedeemPage() {
  Drupal.behaviors.drupalBehaviors_showCartTooltipRedeemPage = {
    attach: function(context, settings) {
      (function($) {
        // Show tooltip in bottom navigation of redeem step 2 and 3
        if ($('body').hasClass('page-redeem-giftcard-choose-giftcard') || $('body').hasClass('page-redeem-giftcard-personal-data')) {
          showToolTip();
        }
        // Show tooltip
        function showToolTip() {
          const $questionMark = $('.info-sign');
          const $popupInfo = $('.hover-giftcards');
          const $popupClose = $('.redeem-cart-close');
          const numberOfCardsInTheCart = $('.redeem-cart .selected-quantity').text();

          //if no cards in the the cart just return;
          if (numberOfCardsInTheCart == 0) {
            return;
          }
          // else {
          // 	$('.hover-giftcards').addClass('hovering');
          // }

          //show popUp window on mouseover ".question_mark" element
          function showPopUp(e){
            e.preventDefault();
            setTimeout(function(){
              ($('.info-sign:hover').length === 0) ? false : $popupInfo.fadeIn(300);
            }, 100)
          };

          //check if mouse hover on popUp window and do not close popUp window
          //if it's true and close it if it's false
          function checkMouse(e){
            e.preventDefault();
            setTimeout(function(){
              ($('.hover-giftcards:hover').length === 0) ? $popupInfo.fadeOut() : false;
            }, 300);
          };

          //close popUp window on mouseleave
          function closePopUp(e){
            e.preventDefault();
            $(this).fadeOut();
          };

          //close popUp window on touch screens
          function closePopUpOnTouch(e){
            e.preventDefault();
            $popupInfo.fadeOut();
          };

          $questionMark.mouseover(showPopUp);
          $questionMark.mouseout(checkMouse);
          $popupInfo.mouseleave(closePopUp);
          $popupClose.mouseover(closePopUpOnTouch);
        }
      })(jQuery);
    }
  }
}
