
// Set position of sidebar on giftcard checkout step 2

module.exports = function bestellenSidebarPosition() {
  (function($) {

    if ( $('body').hasClass('page-card-type-2')) {
        var sidebar = $('.giftcard-pane-cart-sidebar');
        var region = $('section[role="main"]');

        if ( Modernizr.mq('(min-width: ' + '899px)') ) {
            sidebar.css('margin-top', 0);

            var sidebarOffset = sidebar.offset();
            var regionOffset = region.offset();

            var margin = regionOffset.top - sidebarOffset.top;

            sidebar.css('margin-top', margin);
        } else {
            sidebar.css('margin-top', 0);
        }
    }

  })(jQuery);
}
