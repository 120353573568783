
// Style form placeholders

module.exports = function drupalBehaviors_formPlaceholders() {
  Drupal.behaviors.nxte_form_placeholders = {
    attach: function(context, settings) {
    	(function($) {
    		if (typeof (Drupal.settings.nxte) != 'undefined' && typeof (Drupal.settings.nxte.placeholder_elements) != 'undefined') {
    			if (!$('body').hasClass('page-checkout')) {
    				jQuery(Drupal.settings.nxte.placeholder_elements).stickyPlaceholders();
    			}
    		}
      })(jQuery);
    }
  };
}

