
// Create dropdown menu on small screens

module.exports = function toggleMobileMenu() {
  (function($) {

    $('#main-menu').find('.region-navigation-xs').after('<div class="menu-overlay" id="menu-overlay"></div>');

    $('.nav-toggle').click(function(e) {
        e.preventDefault();
        $(this).addClass('active');
        $('.region-navigation-xs').animate({'left': 0}, 400);
        $('#menu-overlay').fadeIn(400);
    });

    $('#menu-overlay').click(function() {
        $(this).fadeOut(400);
        $('.region-navigation-xs').animate({'left': '-100%'}, 400);
    });

  })(jQuery);
}
