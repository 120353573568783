
// Style for input type checkbox

module.exports = function drupalBehaviors_responsiveIcheck() {
    Drupal.behaviors.nxte_responsive_icheck = {
    attach: function(context, settings) {
      (function($) {
          if ($('body').hasClass('imce') || $('body').hasClass('page-checkout'))
                return;
          $('input[type="radio"], input[type="checkbox"]:not(.CybotCookiebotDialogBodyLevelButton)').each(function() {
              if (!$(this).hasClass('icheck-progressed') && $.isFunction($(this).icheck)) {
                  $(this).icheck();
                  $(this).addClass('icheck-progressed');
              }
          });
      })(jQuery);
    }
  };
}
