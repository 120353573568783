
// Add site visitor info like his operating system and browser to the body class;

module.exports = function visitorInfo() {
  (function($) {
    var visitorOS;
    var visitorBrowser;

    if (navigator.userAgent.indexOf('Windows') > 0) {
        visitorOS = 'windows';
    } else if (navigator.userAgent.indexOf('Mac') > 0) {
        visitorOS = 'mac-os';
    } else if (navigator.userAgent.indexOf('Linux') > 0) {
        visitorOS = 'linux';
    }

    if (navigator.userAgent.indexOf('Firefox') > 0) {
        visitorBrowser = 'firefox';
    } else if (navigator.userAgent.indexOf('Chrome') > 0) {
        visitorBrowser = 'chrome';
    } else if (navigator.userAgent.indexOf('Safari') > 0) {
        visitorBrowser = 'safari';
    } else if (navigator.userAgent.indexOf('MSIE') > 0) {
        visitorBrowser = 'ie-browser';
    }

    $('body').addClass(visitorOS);
    $('body').addClass(visitorBrowser);

    var isiPad = navigator.userAgent.match(/iPad/i) != null;

    if (isiPad)
        $('body').addClass('ipad');
    else
        $('body').addClass('not-ipad');
  })(jQuery);
}
