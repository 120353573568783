var setDefaultRowHeights = require("../defaultRowHeights");

// Sets a default .row heights

module.exports = function drupalBehaviors_responsiveRowHeights() {
  Drupal.behaviors.nxte_responsive_row_height = {
    attach: function(context, settings) {
      (function($) {
          setDefaultRowHeights();
      })(jQuery);
    }
  };
}
