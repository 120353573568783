
// Add slick on checkout for mobile devices 

module.exports = function slickOnCheckout() {
  (function($) {
    if (window.matchMedia("(max-width: 516px)").matches) {
      $(".view-wines > .view-content, .view-wrappers > .view-content").slick({
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: "0",
        variableWidth: true
      });
    }
  })(jQuery);
};