// nxte_default.js
var setDefaultRowHeights = require("./modules/defaultRowHeights");
var visitorInfo = require("./modules/visitorInfo");
var stickPlaceholders = require("./modules/stickPlaceholders");

// Drupal behaviors
var drupalBehaviors_formPlaceholders = require("./modules/drupalBehaviors/drupalBehaviors_formPlaceholders");
var drupalBehaviors_lastChilds = require("./modules/drupalBehaviors/drupalBehaviors_lastChilds");
var drupalBehaviors_responsiveEmbedWraper = require("./modules/drupalBehaviors/drupalBehaviors_responsiveEmbedWraper");
var drupalBehaviors_responsiveRowHeights = require("./modules/drupalBehaviors/drupalBehaviors_responsiveRowHeights");
var drupalBehaviors_responsiveIcheck = require("./modules/drupalBehaviors/drupalBehaviors_responsiveIcheck");
var drupalBehaviors_closePopUpOnBgcClick = require("./modules/drupalBehaviors/drupalBehaviors_closePopUpOnBgcClick");
var drupalBehaviors_replaceButtonTextOnHover = require("./modules/drupalBehaviors/drupalBehaviors_replaceButtonTextOnHover");
var drupalBehaviors_showCartTooltipRedeemPage = require("./modules/drupalBehaviors/drupalBehaviors_showCartTooltipRedeemPage");
var drupalBehaviors_fixButtonOnRedeem = require("./modules/drupalBehaviors/drupalBehaviors_fixButtonOnRedeem");

// nxte.js
var kerstpakkettenShowMoreButton = require("./modules/kerstpakkettenShowMoreButton");
var fixNavBarOnScroll = require("./modules/fixNavBarOnScroll");
var goToTopButton = require("./modules/goToTopButton");
var keuzeCadeaubonStepOneFormPreventSubmit = require("./modules/keuzeCadeaubonStepOneFormPreventSubmit");
var toggleMobileMenu = require("./modules/toggleMobileMenu");
var modernizrMediaQueries = require("./modules/modernizrMediaQueries");
var bodyExtraClass = require("./modules/bodyExtraClass");
var slideScrollOnClikableHashLinks = require("./modules/slideScrollOnClikableHashLinks");
var fixSidebarOnScrollStickyKit = require("./modules/fixSidebarOnScrollStickyKit");
var extraProducts = require("./modules/extraProducts");
var uniqCardShowMore = require("./modules/uniqCardShowMore");
var zakelijkPlaceholderDeliveryDate = require("./modules/uniqCardShowMore");
var remarkTextareaAddFocusClass = require("./modules/remarkTextareaAddFocusClass");
var positionSidebarBlocksCheckoutCardType2 = require("./modules/positionSidebarBlocksCheckoutCardType2");
var bestellenSidebarPosition = require("./modules/bestellenSidebarPosition");
var restRestaurantPageSidebarHeight = require("./modules/restRestaurantPageSidebarHeight");
var responsiveHandleResize = require("./modules/responsiveHandleResize");
var slickOnCheckout = require("./modules/slickOnCheckout");
var redeemGiftCard = require("./modules/redeemGiftCard");
var showInfoOnHover = require("./modules/showInfoOnHover");
var lazyLoadingImagesOnScroll = require("./modules/lazyLoadingImagesOnScroll");

(function($) {

  $(document).ready(function() {

    // Add site visitor info like his operating system and browser to the body class;
    visitorInfo();

    // Lazy load images on scroll
    lazyLoadingImagesOnScroll()

    // Set row heights after all images load;
    $('body').imagesLoaded(function($images, $proper, $broken) {
        setDefaultRowHeights();
    });

    // Add show more button for christmas wrappers if content is bigger than needed
    // dynamycally add show more btn, add event listener and remove listener and itself when clicked
    kerstpakkettenShowMoreButton();

    // Fix #navbar on top of the page on scroll event
    fixNavBarOnScroll();

    // Go to top button
    goToTopButton();

    // Prevent submit event on keuze Cadeaubon Step One
    keuzeCadeaubonStepOneFormPreventSubmit();

    // Create dropdown menu on small screens
    toggleMobileMenu();

    // Changes orders of blocks with delivary date on Zakelik page
    zakelijkPlaceholderDeliveryDate();

    // Add focus class on giftcard step 2 remark textarea when somebody focuses in
    remarkTextareaAddFocusClass();

    //set timeout for handling browser resize
    var handleResizeAfterResizeTimeOut;

    $(window).resize(function() {
        clearTimeout(handleResizeAfterResizeTimeOut);
        handleResizeAfterResizeTimeOut = setTimeout(function () {
            responsiveHandleResize();
            restRestaurantPageSidebarHeight();
        }, 100);
    }).trigger('resize');

    // modernizer queries
    modernizrMediaQueries();

    // Add extra class to body depending on the type of the page
    bodyExtraClass();

    // On clickable hash links slide smoothly to them
    slideScrollOnClikableHashLinks();

    $(window).scroll(function() {
      fixSidebarOnScrollStickyKit(
          'page-giftcards-extra',                                             //body class
          '.giftcard-pane-cart-sidebar',                                      //sidebar selector
          '.page-giftcards-extra #giftcard-extra-form > div'                  //parent
      );

      fixSidebarOnScrollStickyKit(
          'page-nxte-checkout-nxte-cardtype-2-step2',                         //body class
          '.nxte-checkout-pane-cart-sidebar',                                 //sidebar selector
          '.page-nxte-checkout-nxte-cardtype-2 .block-system > .content'      //parent
      );
    });

    // Update quantity value of input on extra products when clickicking "+" or "-" on checkout step 2
    extraProducts();

    // Unique cards
    uniqCardShowMore();

    //inserting sidebar into .block-system-main
    //for the 1 checkout step for card_type_2
    positionSidebarBlocksCheckoutCardType2();

    bestellenSidebarPosition();

    //Scrollig of sidebar on bestellen page on LG+
    fixSidebarOnScrollStickyKit(
        'page-giftcards-extra',                                                 //body class
        '.giftcard-pane-cart-sidebar',                                          //sidebar selector
        '.page-giftcards-extra #giftcard-extra-form > div'                      //parent
    );
    //Scrollig of sidebar on bestellen page on LG+ cardtype 2 step 2
    fixSidebarOnScrollStickyKit(
        'page-nxte-checkout-nxte-cardtype-2-step2',                             // body class
        '.nxte-checkout-pane-cart-sidebar',                                     // sidebar selector
        '.page-nxte-checkout-nxte-cardtype-2 .block-system > .content'          //parent
    );

    $(window).on('resize orientationchange', bestellenSidebarPosition);

    //slick on checkout
    slickOnCheckout();

    // Redeem functionality
    redeemGiftCard();

    // Show info on hover on 'i' icon in first step of checkout 
    showInfoOnHover();

  })

  // Style form placeholders
  stickPlaceholders();

  // Style form placeholders
  drupalBehaviors_formPlaceholders();

  // Adds a class of last child to last child element like p or blockquote
  drupalBehaviors_lastChilds();

  // Adds a wraper around objects like: iframe embed object, currently didn't find a place where it's used
  drupalBehaviors_responsiveEmbedWraper();

  // Sets a default .row heights
  drupalBehaviors_responsiveRowHeights();

  // Style for input type checkbox
  drupalBehaviors_responsiveIcheck();

  // Close pop-up's by clicking on overlay
  drupalBehaviors_closePopUpOnBgcClick();

  // Replace button text on hover
  drupalBehaviors_replaceButtonTextOnHover();

  // Show tooltip in bottom navigation of redeem step 2 and 3
  drupalBehaviors_showCartTooltipRedeemPage();

  // Used to add fixing of "next" button behavior on redeem step2
  drupalBehaviors_fixButtonOnRedeem();

})(jQuery);
