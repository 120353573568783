
module.exports = function uniqCardShowMore() {
  (function($) {

    function imagesLazy() {
        $.each($('img'), function() {
            if ( $(this).attr('data-src') && $(this).offset().top < ($(window).scrollTop() + $(window).height() + 100) ) {
                var source = $(this).data('src');
                $(this).attr('src', source);
                $(this).removeAttr('data-src');
            }
        })
    }
    imagesLazy();

    $(window).scroll(function() {
        imagesLazy();
    })

  })(jQuery);
}
