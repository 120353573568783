
// Go to top button

module.exports = function goToTopButton() {
  (function($) {
    var btn = $('#goToTop');

    btn.on('click', function(e){
        e.preventDefault();

        $('html, body').stop().animate({scrollTop: 0}, 300);
    });

  })(jQuery);
}
