
//inserting sidebar into .block-system-main
//for the 1 checkout step for card_type_2

module.exports = function positionSidebarBlocksCheckoutCardType2() {
  (function($) {
    if(!$('body').hasClass('page-card-type-2')) return;
    var sidebarElems = $('.card-type-2-first-step-checkout-sidebar');

    sidebarElems.wrapAll($("<div class='giftcard-pane-cart-sidebar' id='edit-giftcard-pane'></div>"));
    $("#edit-giftcard-pane").insertBefore($('.edit-navigation'));
    $("#block-system-main > .content").addClass("card-type-2-first-step");
  })(jQuery);
}
