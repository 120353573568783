
// Prevent submit event on keuze Cadeaubon Step One

module.exports = function keuzeCadeaubonStepOneFormPreventSubmit() {
  (function($) {

    $('#nxte-keuze-cadeaubon-step-one-form').on('submit', function(ev) {
        ev.preventDefault();
    })

  })(jQuery);
}
