var frontRestTextHeight = require("./frontRestTextHeight");
var setDefaultRowHeights = require("./defaultRowHeights");
var mediaQueriesVars = require('./mediaQueriesVariables');
var mediaQueries = mediaQueriesVars.mediaQueries;
var activeMediaQuery = mediaQueriesVars.activeMediaQuery;

module.exports = function responsiveHandleResize() {

  /**
   * Do things that should be done after resize
   */
  (function($) {

    // sameHeight of blocks on front page;
    frontRestTextHeight();

    //recalculate row heights
    setDefaultRowHeights();

    if (typeof (Drupal.settings.nxte_responsive) != 'undefined' && typeof (Drupal.settings.nxte_responsive.media_queries) != 'undefined') {
        mediaQueries = Drupal.settings.nxte_responsive.media_queries;
    }

    //check for crossed CSS breakpoint
    var newActiveMediaQuery = 'xs';
    if (Modernizr.mq('only all') === false || mediaQueries === false) {
        newActiveMediaQuery = 'none';
    } else {

      $(mediaQueries).each(function(i) {
          if (Modernizr.mq('(min-width: ' + mediaQueries[i].width + 'px)')) {
              newActiveMediaQuery = mediaQueries[i].id;
          }
      });

    }

    if (newActiveMediaQuery != activeMediaQuery) {
      //call function when a CSS breakpoint was crossed
      responsiveMediaQueryChanged(newActiveMediaQuery);
    }

    function responsiveMediaQueryChanged() {
      if (newActiveMediaQuery == 'none' || mediaQueries === false) return;

      /**
       * Do things that should be done after crossing a break-point
       */

      //remove all mq-# classes from body tag
      $('body').removeClass('mq-xs');

      $(mediaQueries).each(function(i) {
          $('body').removeClass('mq-' + mediaQueries[i].id);
      });

      //add current mq-# class to body tag
      $('body').addClass('mq-' + newActiveMediaQuery);

      //leave this
      activeMediaQuery = newActiveMediaQuery;
    }


  })(jQuery);

}
