
// Add extra class to body depending on the type of the page

module.exports = function bodyExtraClass() {
  (function($) {

    if ($('[class*=nxte-body-class]').length == 0) {return}

    var classesAll = $('[class*=nxte-body-class]').attr('class').split(' ');
    var classesExtra = [];

    for (var i = classesAll.length - 1; i >= 0; i--) {
        var result = classesAll[i].match( /nxte-body-class/i );
        if ( result !== null ) {
            classesExtra.push(result.input.replace('nxte-body-class-', ''));
        }
    }
    $('body').addClass(classesExtra.join(' '));

  })(jQuery);
}
