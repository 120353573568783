import debounce from './debounce';

module.exports = function redeemGiftCard() {
  (function($) {

  	$('.form-radio').change(function() {
  		if(this.checked) {
  			var form = $(this).closest('.giftcard-edit-form');
  			let checkedVal = $(this).closest('.form-type-radio').find('.icheck-label').html();
  			form.find("[name='price_input']").val(checkedVal);
  		}
  	});

  	$("[name='price_input']").focus(function(){
  		let trimmedVal = $(this).val().trim().split(",")[0].replace(/[^.\d]+/g, "");
  		$(this).val(trimmedVal);
		});
		
		$("[name='price_input']").each(function(){
			let trimmedVal = $(this).val().trim().replace(/[^.\d]+/g, "");
			if ($(this).val().length>3) {  			
  			$(this).val('€' + trimmedVal);
  		}
  		else if ($(this).val().length>0) {
  			$(this).val('€' + trimmedVal + ',00');
  		}
		})

  	$("[name='price_input']").blur(function(){
  		let trimmedVal = $(this).val().trim().replace(/[^.\d]+/g, "");
  		if ($(this).val().length>3) {  			
  			$(this).val('€' + trimmedVal);
  		}
  		else if ($(this).val().length>0) {
  			$(this).val('€' + trimmedVal + ',00');
  		}
  	});

  	$("[name='price_input']").keyup(function(){
  		let trimmedVal = $(this).val().trim().replace(/[^.\d]+/g, "");
  		$(this).val(trimmedVal);
  	});

  	//fixed button
  	if ($('body').hasClass('page-redeem-giftcard-choose-giftcard')) {

      //close button on redeem kard
      var $closeCard = $('.close-button');
      $closeCard.mousedown(function() {
        var removeButton = $(this).closest('form').find('.remove-button');
        removeButton.trigger('mousedown');
      })

      // card balance heandler on redeem-giftcard/choose-giftcard
      $('.available_balance').click(function() {
        var cardForm = $(this).closest('.redeem-giftcard-selection-form');
        var cardFormEditPrice = cardForm.find('.form-item-price-input input');
        var avalibleBalanceValue = $(this).html().trim().split(",")[0].replace(/[^.\d]+/g, "");
        var cardFormSubmit = cardForm.find('input[value="Toevoegen"], input[value="Add"]');

        cardFormEditPrice.val(avalibleBalanceValue);
        cardFormSubmit.trigger("mousedown");
      });

  	}
		//fixed footer on redeem-giftcard/choose-giftcard

		if ($('body').hasClass('page-redeem-giftcard-choose-giftcard')) {
			const $body = $('body');

			function stickFooter() {
					$body.addClass('js_stickFooter js_stickFooter_visible');
			}

			function unstickFooter() {
					if ($body.hasClass('js_stickFooter_visible')) {
					$body.removeClass('js_stickFooter_visible');

					setTimeout(function() {
							$body.removeClass('js_stickFooter');
					}, 400);
					}
			}

			function handleStickyFooter() {
					const { innerHeight } = window;
					const { top: contentTop, bottom: contentBottom } = $('.view-content').get(0).getBoundingClientRect();
					const isContentVisible = contentTop + 200 < innerHeight && contentBottom > innerHeight;

					if (isContentVisible) {
							stickFooter();
					} else {
							unstickFooter();
					}
			}

			const debouncedHandleStickyFooter = debounce(handleStickyFooter);

			window.onscroll = function() {
					debouncedHandleStickyFooter();
			};
		}
  })(jQuery);
}
