
// modernizr MediaQueries

module.exports = function modernizrMediaQueries() {
  (function($) {
    if (Modernizr.mq('only all') === false) {
        $('body').addClass('no-mq');
    }
    else {
        $('body').addClass('mq');
    }
  })(jQuery);
}
