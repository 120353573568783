
// On clickable hash links slide smoothly to them

module.exports = function slideScrollOnClikableHashLinks() {
  (function($) {
    $('a[href*=#]').each(function() {
        if ($(this.hash).length > 0) {
            $(this).click(function(e) {
                e.preventDefault();
                $('html,body').animate({
                    scrollTop: ($(this.hash).offset().top - $('#navbar').height() - 100)
                }, 300);
            });
        }
    });
  })(jQuery);
}
