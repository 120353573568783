
// Add focus class on giftcard step 2 remark textarea when somebody focuses in

module.exports = function remarkTextareaAddFocusClass() {
  (function($) {

    if ( $('body').hasClass('page-giftcards') && $('.form-item-s-remark').length > 0 ) {
        $('.form-item-s-remark').find('textarea').focusin(function() {
            $(this).addClass('focus');
        });

        $('.form-item-s-remark').find('textarea').focusout(function() {
            $(this).removeClass('focus');
        });
    }

  })(jQuery);
}
