
// sameHeight of blocks on front page;

module.exports = function frontRestTextHeight() {
  (function($) {
    var view = $('#block-views-view-1-block-1');
    if (!$('body').hasClass('front') && view.length == 0) { return; }

    var heights = [];

    view.find('.field-name-body').each(function(index, el) {
        heights.push($(this).height());
    });

    var maxHeight = Math.max.apply(Math, heights);

    view.find('.field-name-body').height(maxHeight);
  })(jQuery);
}
