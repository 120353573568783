/**
* Returns a function, that, as long as it continues to be invoked, will not
* be triggered. The function will be called after it stops being called for
* N milliseconds. If `immediate` is passed, trigger the function on the
* leading edge, instead of the trailing.
* https://davidwalsh.name/essential-javascript-functions
*
* @param      {function}  func       Source function
* @param      {number}    wait       Miliseconds to wait
* @param      {boolean}   immediate  Triggers the function on the leading
*                                    edge
* @return     {function}  Debounced function
*/

module.exports = function debounce(func, wait, immediate) {
  var timeout;
  return function() {
      var context = this, args = arguments;
      var later = function() {
          timeout = null;
          if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
  };
}
