// Show info on hover on 'i' icon in first step of checkout 

module.exports = function showInfoOnHover() {
  (function($) {
    $('.delivery-info').on('hover', function() {
      $('.info-text').slideToggle();
    });
    $(window).on('touchstart', function() {
      $('.delivery-info').off().click(function() {
        $('.info-text').slideToggle();
      });
    });
  })(jQuery);
}
