
// Update quantity value of input on extra products when clickicking "+" or "-" on checkout step 2

module.exports = function extraProducts() {
  (function($) {

    if (!$('body').hasClass('page-giftcards-extra')) { return; }

    var arr = $('[id^=edit-ammount-wrapper]');
    arr.each(function() {

        var input = $('input[type=text]', this);
        var amount = parseInt(input.val());

        $(this).find('a:first-child').click(function(e) {
            e.preventDefault();
            amount = amount < 2 ? amount : amount - 1;
            input.val( amount );
        });

        $(this).find('a:last-child').click(function(e) {
            e.preventDefault();
            amount += 1;
            input.val( amount );
        });
    });

  })(jQuery);
}
