
//Scrollig of sidebar on bestellen page on LG+
// Function bestellenSidebarScroll() Is not used now. There is a bug in FF when sidebar reverberates on scroll
// Sticky kit plugin is used instead. Check fixSidebarOnScroll function

module.exports = function fixSidebarOnScrollStickyKit(pageClass, sidebar, parent) {
  (function($) {
    if (!Modernizr.mq('(min-width: ' + '899px)') || !($('body').hasClass(pageClass))) {
        return;
    }
    $(sidebar).stick_in_parent({
        parent: parent,
        offset_top: 20
    }).on('sticky_kit:bottom', function(e) {
        $(this).parent().css('position', 'static');
    }).on('sticky_kit:unbottom', function(e) {
        $(this).parent().css('position', 'relative');
    });
  })(jQuery);
}
