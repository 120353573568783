
// Replace button text on hover

module.exports = function drupalBehaviors_replaceButtonTextOnHover() {
    Drupal.behaviors.buttonHover = {
        attach: function(context, settings) {
            (function($) {
                if ( !($('body').hasClass('page-overzicht') || $('body').hasClass('front')) ) { return; }

                var view = $('.view-card-types').find('.view-content');

                view.on('mouseenter', '.views-row', function(event) {
                    var link = $(this).find('p a');
                    link.data('title', 0);
                    if ( !link.data('title').length ) { link.data('title', link.text() ); }
                    link.text('Direct bestellen');
                });

                view.on('mouseleave', '.views-row', function(event) {
                    var link = $(this).find('p a');
                    link.text( link.data('title') );
                });
            })(jQuery);
        }
    };
}
